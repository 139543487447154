<template>
  <span
    class="h-4 inline-flex items-center justify-center font-bold rounded-full px-2 text-mono text-xs ml-1 bg-primary-100 text-primary-800 dark:bg-primary-500 dark:text-gray-800"
  >
    <slot />
  </span>
</template>

<script>
export default {
  //
}
</script>
