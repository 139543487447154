<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530 560">
    <g fill="none" fill-rule="evenodd" transform="translate(4 10)">
      <path
        fill="#DDE4EB"
        d="M0 185a19.4 19.4 0 0 1 19.4-19.4h37.33a19.4 19.4 0 0 0 0-38.8H45.08a19.4 19.4 0 1 1 0-38.8h170.84a19.4 19.4 0 0 1 0 38.8h-6.87a19.4 19.4 0 0 0 0 38.8h42.55a19.4 19.4 0 0 1 0 38.8H19.4A19.4 19.4 0 0 1 0 185z"
      />
      <g stroke-width="2" transform="rotate(-30 383.9199884 -24.79114317)">
        <rect
          width="32.4"
          height="9.19"
          x="12.47"
          y="3.8"
          fill="#FFF"
          stroke="#0D2B3E"
          rx="4.6"
        />
        <rect
          width="32.4"
          height="14.79"
          x="1"
          y="1"
          fill="#FFF"
          stroke="#0D2B3E"
          rx="7.39"
        />
        <ellipse
          cx="8.6"
          cy="8.39"
          stroke="#4A90E2"
          rx="7.6"
          ry="7.39"
          style="mix-blend-mode: multiply"
        />
      </g>
      <path
        fill="#E0EEFF"
        d="M94 198.256L106.6 191l22.4 16.744L116.4 215zM48 164.256L60.6 157 83 173.744 70.4 181z"
        opacity=".58"
      />
      <path
        stroke="#0D2B3E"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M88 188l9 7-9-7zm-15-11l5 3-5-3z"
      />
      <path
        stroke="#4A90E2"
        stroke-width="2"
        d="M92.82 198.36l20.65 15.44 10.71-6.16-20.65-15.44-10.71 6.16zM119 211l-22-17 22 17zm-72.18-46.64l20.65 15.44 10.71-6.16-20.65-15.44-10.71 6.16zM73 178l-22-17 22 17z"
      />
      <path
        stroke="#8DDCFF"
        stroke-linecap="round"
        stroke-width="2"
        d="M117 176a14 14 0 0 0-14-14m10 15a10 10 0 0 0-10-10"
      />
      <ellipse cx="258" cy="441" fill="#FFF" rx="250" ry="90" />
      <path
        fill="#FFF"
        fill-rule="nonzero"
        stroke="#0D2B3E"
        stroke-width="2"
        d="M195.95992276 433.88207738c-.7613033-1.55811337-1.97677352-5.39619.01107483-6.1324365 1.97685786-.72734656 2.77032762 2.34241006 4.31210683 4.22387675 2.92231431 3.57504952 6.28818967 5.22592295 11.14145652 5.73602185 1.77024897.18606067 3.51532102.0376574 5.19229942-.41955529a3.17 3.17 0 0 1 3.89461497 2.16898002 3.12 3.12 0 0 1-2.19463454 3.85169823c-2.43329264.66931826-4.97971626.88432232-7.54558275.61463889-7.06110546-.7421521-11.79595772-3.81390631-14.81133528-10.04322395z"
      />
      <g stroke="#0D2B3E" stroke-width="2">
        <path
          fill="#FFF"
          fill-rule="nonzero"
          d="M228.66635404 453.35751889l3.48444585 6.7411525a11.71 11.71 0 0 0-3.36066168 18.19840799l3.157266 3.1573203-8.52104352 8.55618006-.29468882-6.6673277a19.31 19.31 0 0 1 5.53468217-29.98573315z"
        />
        <path d="M221.75370493 481.33823157l5.9097851-4.56727928" />
      </g>
      <g stroke="#0D2B3E" stroke-width="2">
        <path
          fill="#FFF"
          fill-rule="nonzero"
          d="M217.43675157 454.38903415l-.38056208 7.58726384a10.25 10.25 0 0 0-10.62036709 8.5642456l.04580558 4.00318647-11.36366293-.10613565 3.84834642-5.16425501a17.82 17.82 0 0 1 18.46098491-14.88104957z"
        />
        <path d="M199.40986905 468.0735658l7.07551171 1.72015522" />
      </g>
      <path
        fill="#E5F7FF"
        d="M233.41788355 435.98904264l3.14268919.33030994-3.01041974 28.64223059-3.1426892-.33030995z"
      />
      <path
        stroke="#7ED7FF"
        stroke-width="2"
        d="M218.1633805 433.70198413l13.07796292 1.37454929 1.09127716-10.38280859a6.575 6.575 0 0 0-13.07796293-1.37454929l-1.09127715 10.38280859z"
      />
      <path
        fill="#FFF"
        stroke="#0D2B3E"
        stroke-width="2"
        d="M221.02136188 434.25374714l.64389533-6.12625487a3.59 3.59 0 1 1 7.130722.74946908l-.64389534 6.12625488"
      />
      <path
        stroke="#0D2B3E"
        stroke-width="2"
        d="M235.80327328 436.92350283l-20.28824667-2.13238065-2.86721575 27.27973559 20.28824667 2.13238065 2.86721575-27.2797356z"
      />
      <path
        fill="#FFF"
        stroke="#0D2B3E"
        stroke-width="2"
        d="M215.51502661 434.79112218l-2.86721575 27.27973559 17.1555027 1.80311599 2.86721575-27.2797356-17.1555027-1.80311598z"
      />
      <path
        fill="#FFF"
        stroke="#0D2B3E"
        stroke-width="2"
        d="M214.36589556 440.07997818l-1.09905036.88999343-1.17489993 11.1784261 11.15853567 1.17280937 1.09905036-.88999344 1.17385464-11.16848088-11.16848088-1.17385464z"
      />
      <path
        fill="#FFF"
        fill-rule="nonzero"
        stroke="#0D2B3E"
        stroke-width="2"
        d="M245.62684398 462.24908175c-.41742893 1.6755456-1.95466376 5.39523768-3.94116941 4.68369338-1.99645087-.71258958-.63076284-3.56546466-.5955535-6.00514913.06313174-4.61870267-1.45795198-8.03642184-4.8492445-11.55015704-1.23234204-1.2858589-2.67505657-2.29217634-4.24858182-3.01059006a3.17 3.17 0 0 1-1.5730205-4.16725407 3.12 3.12 0 0 1 4.14422777-1.54527542c2.29328456 1.04544055 4.3804078 2.52169139 6.1770892 4.36961887 4.93145874 5.12354512 6.58580412 10.52606688 4.87526226 17.2340134z"
      />
      <path
        stroke="#233242"
        stroke-width="2"
        d="M518 372.93A1509.66 1509.66 0 0 0 261 351c-87.62 0-173.5 7.51-257 21.93"
      />
      <circle cx="51" cy="107" r="6" fill="#9AC2F0" />
      <path
        stroke="#031836"
        stroke-linecap="round"
        stroke-width="2"
        d="M48 116a6 6 0 1 0-6-6"
      />
      <circle cx="501" cy="97" r="6" fill="#9AC2F0" />
      <path
        stroke="#031836"
        stroke-linecap="round"
        stroke-width="2"
        d="M498 106a6 6 0 1 0-6-6"
      />
      <path
        fill="#031836"
        d="M305.75 0h.5a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-.5a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zM321 14.75v.5a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-.5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1zM306.25 30h-.5a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h.5a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zM291 15.25v-.5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v.5a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1z"
      />
      <path
        fill="#DDE4EB"
        d="M446 107.5a16.5 16.5 0 0 0 16.5 16.5h44a16.5 16.5 0 0 1 0 33h-143a16.5 16.5 0 0 1 0-33 16.5 16.5 0 0 0 0-33h-66a16.5 16.5 0 0 1 0-33h165a16.5 16.5 0 0 1 0 33 16.5 16.5 0 0 0-16.5 16.5z"
      />
      <circle cx="458" cy="186" r="4" fill="#031836" />
      <circle cx="138" cy="16" r="4" fill="#031836" />
      <path
        stroke="#233242"
        stroke-width="2"
        d="M58 364.86l67.93-67.93a10 10 0 0 1 14.14 0L196 352.86m139-18l36.93-36.93a10 10 0 0 1 14.14 0L451 362.86"
      />
      <path
        stroke="#233242"
        stroke-width="2"
        d="M176 332.86l70.93-71.84a10 10 0 0 1 14.19-.05L345 344.86"
      />
      <g stroke-width="2" transform="rotate(-87 355.051 43.529)">
        <ellipse
          cx="10.28"
          cy="27.49"
          fill="#FFF"
          stroke="#0D2B3E"
          rx="9.21"
          ry="19.26"
        />
        <path
          fill="#FFF"
          stroke="#0D2B3E"
          d="M25.66 54.03c-7.52 0-13.62-12.1-13.62-27.02S18.14 0 25.66 0H96.1c7.22 0 14.15 2.85 19.26 7.91l19.26 19.1-19.26 19.1a27.35 27.35 0 0 1-19.26 7.92H25.66z"
        />
        <path
          fill="#FFF"
          stroke="#4A90E2"
          d="M98.09 54.22c-7.52 0-13.62-12.1-13.62-27.02s6.1-27 13.62-27"
        />
        <ellipse cx="59.59" cy="27.27" stroke="#4A90E2" rx="16.34" ry="16.21" />
        <ellipse
          cx="59.59"
          cy="27.27"
          fill="#FFF"
          stroke="#0D2B3E"
          rx="12.26"
          ry="12.16"
        />
      </g>
      <g stroke="#233242" stroke-width="2" transform="translate(456 396)">
        <ellipse cx="30" cy="10" rx="20" ry="10" />
        <path
          d="M0 15c0 8.28 13.43 15 30 15m12.39-1.33C52.77 26.3 60 21.07 60 15"
        />
      </g>
      <g stroke="#233242" stroke-width="2" transform="translate(276 520)">
        <ellipse cx="20" cy="6.67" rx="13.33" ry="6.67" />
        <path
          d="M0 10c0 5.52 8.95 10 20 10m8.26-.89C35.18 17.54 40 14.05 40 10"
        />
      </g>
      <g stroke="#233242" stroke-width="2" transform="translate(186 370)">
        <ellipse cx="15" cy="5" rx="10" ry="5" />
        <path
          d="M0 7.5C0 11.64 6.72 15 15 15m6.2-.67c5.19-1.18 8.8-3.8 8.8-6.83"
        />
      </g>
      <ellipse cx="58" cy="492" fill="#202C3A" rx="3" ry="2" />
      <ellipse cx="468" cy="492" fill="#202C3A" rx="3" ry="2" />
      <ellipse cx="388" cy="392" fill="#202C3A" rx="3" ry="2" />
      <ellipse cx="338" cy="452" fill="#202C3A" rx="3" ry="2" />
      <g stroke="#233242" stroke-width="2" transform="translate(46 406)">
        <ellipse cx="40" cy="13.33" rx="26.67" ry="13.33" />
        <path
          d="M0 20c0 11.05 17.9 20 40 20m16.51-1.78C70.37 35.08 80 28.1 80 20"
        />
      </g>
      <g stroke="#0D2B3E" stroke-width="2">
        <path
          d="M299 378l-21 42m35-36l-21 42m4-42l14 6m-17 0l14 6m-17 0l14 6m-17 0l14 6m-17 0l14 6m-17 0l14 6"
        />
      </g>
      <circle cx="341" cy="155" r="25" stroke="#233242" stroke-width="2" />
      <circle cx="342" cy="156" r="20" fill="#FFF" />
      <path
        stroke="#233242"
        stroke-width="2"
        d="M321.56 140.5c-7.66.32-13 2.37-13.97 6-1.78 6.66 11.9 16.12 30.58 21.13 18.67 5 35.25 3.65 37.04-3.02.96-3.58-2.54-7.96-8.88-12.03"
      />
    </g>
  </svg>
</template>
