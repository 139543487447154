<template>
  <svg
    class="block mx-auto mb-6"
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="2"
    viewBox="0 0 100 2"
  >
    <path fill="#D8E3EC" d="M0 0h100v2H0z"></path>
  </svg>
</template>
