<template>
  <div class="bg-gray-100 dark:bg-gray-700 px-6 py-3 flex">
    <slot />
  </div>
</template>

<script>
export default {
  //
}
</script>
