<template>
  <CustomAppError />
</template>

<script>
import Guest from '@/layouts/Guest'

export default {
  name: 'AppErrorPage',

  layout: Guest,
}
</script>
