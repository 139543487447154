<template>
  <Heading
    :level="3"
    class="border-b border-gray-100 dark:border-gray-700 py-4 px-8"
  >
    <slot />
  </Heading>
</template>

<script>
export default {
  //
}
</script>
