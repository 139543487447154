<template>
  <div class="py-3 px-8">
    <slot />
  </div>
</template>

<script>
export default {
  //
}
</script>
