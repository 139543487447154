<template>
  <div
    class="relative overflow-hidden bg-white dark:bg-gray-800 rounded-lg shadow"
  >
    <slot />
  </div>
</template>

<script>
export default {
  //
}
</script>
