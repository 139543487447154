<template>
  <CustomError403 />
</template>

<script>
import Guest from '@/layouts/Guest'

export default {
  name: 'Error403Page',

  layout: Guest,
}
</script>
